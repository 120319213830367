<script lang="ts">
  import { GlobalRouter } from '@/types';
  import { Context } from 'page';
  import { getContext, SvelteComponent } from 'svelte';
  import { Writable } from 'svelte/store';
  export let layout = null;
  export let component: SvelteComponent = null;

  const context: Writable<Context> = getContext('context');
  const { navigate } = getContext('router') as GlobalRouter;
</script>

{#if layout}
  <svelte:component this={layout} {navigate} context={$context} {component} />
{:else if component}
  <svelte:component this={component} {...$$props} context={$context} />
{/if}
