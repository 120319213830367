<script lang="ts">
  import { EventsBus } from '@/EventsBus';
  import Rollbar from '@/Rollbar.svelte';
  import { Router } from '@/routing';
  import { Notifications } from '@/types';
  import { getWidthBreakpoints } from '@/utils';
  import { Snackbar } from '@nextmillenniummedia/platformui';
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';
  import { setContext } from 'svelte';
  import { entities } from './MainEntities';
  import { Analytics } from './analytics';
  import { UserRole, userState } from './entities/auth';
  import { UserState } from './entities/auth.types';

  const removeLoader = () => {
    const app = document.getElementById('app');
    const loaderWrapper = app.querySelector('.init-loader-wrapper');
    loaderWrapper.classList.add('hidden');
  };

  type Notification = {
    type: string;
    message?: string;
  };
  let notification: Notification = null;
  let notificationTimeout = null;
  const notificationHandler = (type: string) => (message: string) => {
    if (notificationTimeout) {
      clearTimeout(notificationTimeout);
    }
    notification = { type, message };
    notificationTimeout = setTimeout(() => {
      notification = null;
    }, 5000);
  };

  const notifications: Notifications = {
    info: notificationHandler('info'),
    error: notificationHandler('error'),
    warn: notificationHandler('warn'),
    success: notificationHandler('success'),

    hide: () => {
      notification = null;
    },
  };
  setContext('notifications', notifications);

  EventsBus.instance().on('error', (e) => {
    notifications.error(e.message);
  });
  EventsBus.instance().on('warn', (message: string) => {
    notifications.warn(message);
  });
  EventsBus.instance().on('success', (message: string) => {
    notifications.success(message);
  });

  EventsBus.instance().on('logout', () => {
    firstLoadSub.unsubscribe();
    unsubcribe();
  });

  setContext('widthBreakpoints', getWidthBreakpoints());
  entities.init();

  setContext('entities', entities);
  entities.announcementsEntity.deleteStaleAnnouncements();
  const userBehaviorSubject = new BehaviorSubject({} as UserState);
  const unsubcribe = userState.subscribe((state) => {
    userBehaviorSubject.next(state);
  });
  const firstLoadSub = userBehaviorSubject
    .pipe(
      filter((state: UserState) => Boolean(state.uid)),
      distinctUntilChanged(),
    )
    .subscribe((state) => {
      entities.notificationsEntity.loadAll();
      entities.blackListEntity.loadAll(state.uid);
      if ([UserRole.publisher, UserRole.advertiser].includes(state.userStatus)) {
        entities.announcementsEntity.load();
      }
    });

  const announcements$ = entities.announcementsEntity.store;
  const env = import.meta.env.VITE_NODE_ENV;

  const analytics = new Analytics();
  setContext('analytics', analytics);
  analytics.page();

  const observer = new MutationObserver(() => {
    if (document.querySelector('#app main')) {
      removeLoader();
      observer.disconnect();
    }
  });

  const app = document.getElementById('app');

  if (app) {
    observer.observe(app, {
      childList: true,
      subtree: true,
    });
  }

  announcements$.subscribe((state) => {
    if (state.announcement) {
      entities.announcementsEntity.showAnnouncement(state.announcement);
    }
  });
</script>

<Router />
{#if env === 'production'}
  <Rollbar />
{/if}
<div class="relative">
  {#if notification?.message}
    <Snackbar
      on:close={() => {
        notification = null;
      }}
      type={notification.type}
      timeout={5000}
      withTitle
    >
      {@html notification?.message || ''}
    </Snackbar>
  {/if}
</div>
<div class="toast-wrapper">
  <SvelteToast />
</div>

<svelte:options immutable={true} />

<style global>
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  :root {
    --toastContainerTop: auto;
    --toastContainerRight: 10vw;
    --toastContainerBottom: 2rem;
    --toastContainerLeft: 10vw;
    --toastWidth: 80vw;
  }

  .toast-wrapper :global(._toastMsg) {
    text-align: center;
  }
</style>
